<template>
  <v-card class="upload-card fill-height" flat>
    <v-card-actions>
      <v-spacer />
      <v-dialog> </v-dialog>
      <v-btn @click="editEntry()">Neuer Eintrag</v-btn>
    </v-card-actions>

    <v-data-table
      :headers="headers"
      :items="interestRates"
      :loading="loading"
      hide-actions
    >
      <template #items="{ item }">
        <tr>
          <td>{{ item.valid_from }}</td>
          <td>
            {{ item.valid_until ? item.valid_until : `unbegrenzt` }}
          </td>
          <td>{{ item.rate }}%</td>
          <td>
            <v-btn icon @click="editEntry(item)"><v-icon>edit</v-icon></v-btn>
            <v-btn icon @click="removeEntry(item.id)"
              ><v-icon>delete</v-icon></v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>
    <ModalSingleRate v-model="dialog" :entry="editedEntry" @save="saveEntry" />
  </v-card>
</template>

<script>
import interestRatesApi from '@/api/interestRatesApi'
import ModalSingleRate from '@/pages/admin/interestRates/ModalSingleRate'
import formatDate from 'date-fns/format'

export default {
  name: 'InterestRates',
  components: { ModalSingleRate },
  data() {
    return {
      dialog: false,
      loading: false,
      headers: [
        { text: 'Gültig ab', value: 'valid_from', sortable: false },
        { text: 'Gültig bis', value: 'valid_until', sortable: false },
        { text: 'Zinssatz pro Monat', value: 'rate', sortable: false },
        { text: '', value: 'actions', align: 'right', sortable: false },
      ],
      interestRates: [],
      editedEntry: null,
    }
  },
  methods: {
    async getInterestRates() {
      try {
        this.loading = true
        this.interestRates = await interestRatesApi.getAll()
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
    async saveEntry(data) {
      const { id, valid_from, rate } = data

      try {
        if (id) {
          await interestRatesApi.update(id, valid_from, rate)
          this.$root.setSnackbar('success', 'Eintrag wurde aktualisiert')
        } else {
          await interestRatesApi.create(valid_from, rate)
          this.$root.setSnackbar('success', 'Eintrag wurde erstellt')
        }
        await this.getInterestRates()
      } catch {
        this.$root.setSnackbar(
          'error',
          'Eintrag konnte nicht gespeichert werden'
        )
      }
    },
    async removeEntry(id) {
      try {
        await interestRatesApi.removeEntry(id)
        await this.getInterestRates()
        this.$root.setSnackbar('success', 'Eintrag wurde entfernt')
      } catch {
        this.$root.setSnackbar('error', 'Eintrag konnte nicht entfernt werden')
      }
    },
    editEntry(entry) {
      const currentMonth = formatDate(new Date(), 'MM.YYYY')
      this.editedEntry = entry ?? { valid_from: currentMonth, rate: 0 }
      this.dialog = true
    },
  },
  mounted() {
    this.getInterestRates()
  },
}
</script>

<style scoped></style>
