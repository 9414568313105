<template>
  <v-layout row justify-center>
    <v-dialog v-model="isDialog" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <v-dialog
                  ref="dialog"
                  v-model="monthDialog"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formattedDate"
                      label="Gültig ab"
                      append-icon="event"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="dataEntry.valid_from"
                    type="month"
                    scrollable
                    @input="pickDate"
                  >
                  </v-date-picker>
                </v-dialog>
                <v-text-field
                  v-model="dataEntry.rate"
                  label="Zins"
                  @change="() => (dataEntry.rate = repairRate(dataEntry.rate))"
                  suffix="%"
                  :rules="[rules.validRate]"
                  placeholder="0.4"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" flat @click="isDialog = false"
            >Abbrechen</v-btn
          >
          <v-btn color="blue darken-1" primary flat @click="save"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { isDialog } from '@/mixins/isDialog'

export default {
  mixins: [isDialog],
  props: {
    entry: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    monthDialog: false,
    dataEntry: {
      valid_from: null,
      rate: null,
    },
    rules: {
      validRate: (value) => {
        if (Number.isNaN(parseFloat(value))) {
          return 'Keine gültige Zahl'
        }
        return true
      },
    },
  }),
  watch: {
    entry: {
      immediate: true,
      handler(value) {
        if (value) {
          this.dataEntry.valid_from = this.parseDate(value.valid_from)
          this.dataEntry.rate = value.rate
        }
      },
    },
  },
  methods: {
    pickDate(selectedDate) {
      this.dataEntry.valid_from = `${selectedDate}-01`
      this.monthDialog = false
    },
    save() {
      const valid_from = this.formattedDate
      const rate = this.dataEntry.rate
      const id = this.entry.id
      this.$emit('save', { valid_from, rate, id })
      this.isDialog = false
    },
    parseDate(date) {
      if (!date) return null
      const [month, year] = date.split('.')
      return `${year}-${month}-01`
    },
    formatDate(date) {
      if (!date) return null
      const [year, month] = date.split('-')
      return `${month}.${year}`
    },
    repairRate(rate) {
      const value = parseFloat(rate.replace(/,/g, '.'))
      if (Number.isNaN(value)) {
        return 0
      }
      return value
    },
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.dataEntry.valid_from)
    },
  },
}
</script>
